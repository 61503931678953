<template>
    <v-form ref="formRef" v-model="valid" :disabled="disabled">
        <slot />

        <v-divider v-if="showSubmit" class="pb-2" />
        <v-row v-if="showSubmit">
            <v-col md="6"><slot name="actions"></slot></v-col>
            <v-col md="6" class="d-flex justify-end">
                <slot name="cancel-actions"></slot>
                <v-btn
                    class="ml-4"
                    :disabled="!valid || disabled"
                    color="primary"
                    data-cy="form-submit"
                    @click="doSubmit"
                >
                    {{ submitText }}
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import setVar from '@/helpers/variable-set'
import equal from 'deep-equal'

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        formData: {
            type: Object,
            default: () => ({}),
        },
        formReady: {
            type: Boolean,
            default: false,
        },
        submit: {
            type: Function,
            default: () => () => {},
        },
        showSubmit: {
            type: Boolean,
            default: true,
        },
        checkDirty: {
            type: Boolean,
            default: true,
        },
        submitText: {
            type: String,
            default: 'Submit',
        },
    },
    data: () => ({
        valid: false,
        initialFormData: {},
        submitting: false,
    }),
    watch: {
        formReady: {
            //Once the form is ready (all data has been set) then it'll make a copy of the formData
            handler(value) {
                if (!this.checkDirty) {
                    return
                }

                if (!value) {
                    this.setDirty(false)
                }
                this.initialFormData = Object.assign({}, this.formData)
            },
        },
        formData: {
            /*
            Compare the initial form data with the new form data.
            Added extra handling for:
              * Initial value was 'null' but new value is empty string ''
              * Initial value was 'null' but new value is a boolean
            These special cases occur because the initial values were null but vuetify returned something
            else based on the field type.
           */
            handler(formData) {
                if (!this.formReady || this.submitting || !this.checkDirty) {
                    return
                }

                Object.keys(formData).forEach((key) => {
                    //When initial form data is null and the new form data is false reset back to null so the comparison works correctly
                    if (this.initialFormData[key] === null && formData[key] === false) {
                        formData[key] = this.initialFormData[key]
                    }
                })

                //compare using deep-equal library
                let isDirty = !equal(this.initialFormData, formData)

                this.setDirty(isDirty)
            },
            deep: true,
        },
        valid(valid) {
            this.$emit('formValid', valid)
        },
    },
    methods: {
        doSubmit() {
            this.setDirty(false)
            this.submitting = true
            this.submit()
        },
        setDirty(isDirty) {
            if (!this.checkDirty) {
                return
            }

            setVar('$dirtyForm', isDirty)
        },
        validate() {
            return this.$refs.formRef.validate()
        },
        reset() {
            this.$refs.formRef.reset()
        },
    },
}
</script>
